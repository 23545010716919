
  
  /* Apply styles to the container */
  .container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
     /* Prevent scrollbars */
     overflow: hidden;
  }
  
  /* Apply styles to the content */
  .content {
    text-align: center;
    padding: 20px; /* Add some padding inside the content */
  }
  
  .content h1 {
    font-size: 3rem; /* Responsive font size */
    color: white;
    margin-bottom: 1rem;
  }
  
  .anchor {
    font-size: 1.5rem; /* Responsive font size */
    color: white;
    text-decoration: none;
    border: 2px solid white;
    padding: 0.75rem 1.5rem;
    border-radius: 50px;
    transition: 0.3s;
  }
  
  .anchor:hover {
    background-color: white;
    color: #333;
  }
  
  /* Ensure the video background is properly styled */
  .bg-video {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%; /* Ensure the video covers the container */
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    z-index: -2; /* Ensure video is behind other content */
    
  }
  